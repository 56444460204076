import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import { theme } from "../../utils/muiTheme";
import Header from "../Header";
import Intro from "../Intro";
import Login from "../Login";
import Upload from "../Upload";
import translations from "../../i18n/translations";
import "./App.css";

import { ThemeProvider } from "@mui/material/styles";

function App() {
  const [locale, setLocale] = useState(
    localStorage?.getItem("locale") || "he-HE",
  );
  const [intro, setIntro] = useState(false);
  const [auth, setAuth] = useState(false);
  const [token, setToken] = useState("");

  const handleAuth = (token) => {
    setToken(token);
    setAuth(true);
  };

  const changeLocale = (locale) => {
    setLocale(locale);
    localStorage?.setItem("locale", locale);
  };

  const logout = () => {
    setAuth(false);
  };

  return (
    <IntlProvider
      locale={locale}
      defaultLocale="he-HE"
      messages={translations[locale]}
    >
      <ThemeProvider theme={theme}>
        <div className={locale !== "he-HE" ? "App ltr" : "App"}>
          <Header setLocale={changeLocale} />
          {!auth ? (
            !intro ? (
              <Intro handleContinue={setIntro} />
            ) : (
              <Login handleAuth={handleAuth} />
            )
          ) : (
            <Upload token={token} logout={logout} />
          )}
        </div>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
