import axios from "axios";
import { API_URL, ENV } from "./consts";

export const sendOTP = async (phoneNumber) => {
  return await axios.post(
    API_URL,
    {
      phoneNumber,
      action: "generate",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

export const validateOTP = async (phoneNumber, enteredOTP) => {
  return await axios.post(
    API_URL,
    {
      phoneNumber,
      enteredOTP,
      action: "verify",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

export const signParams = async (params, token) => {
  return await axios.post(
    API_URL,
    {
      params,
      token,
      action: "sign",
      env: ENV,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getCitiesGov = async () => {
  const url = `https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=1272`;

  const res = await axios({
    method: "get",
    url,
  });
  return res.data.result.records.map((item) => ({
    cityHe: item["שם_ישוב"]
      .trim()
      .replace("(", "{")
      .replace(")", "(")
      .replace("{", ")"),
    cityEn: capitalizeFirstLetter(item["שם_ישוב_לועזי"].trim().toLowerCase()),
    id: item["סמל_ישוב"],
  }));
};
