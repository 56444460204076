import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import OtpInput from "react18-input-otp";
import { useMessage } from "../../i18n";
import useDigitInput from "../../utils/useDigitInput";
import DigitInputElement from "./components/DigitInputElement";
import { sendOTP, validateOTP } from "../../data/apis";
import "./Login.scss";

const Login = ({ handleAuth }) => {
  const t = useMessage();
  const [sentPhone, setSentPhone] = useState(false); // temp
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [error, setError] = useState("");

  const [phone, onPhoneChange] = useState("");
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 10,
    value: phone,
    onChange: onPhoneChange,
  });

  const [code, onCodeChange] = useState("");

  const handlePhone = async (e) => {
    e.preventDefault();
    setIsLoadingOtp(true);
    setError("");
    try {
      await sendOTP(phone);
      setSentPhone(true);
    } catch (e) {
      const responseError = e?.response?.data?.error;
      if (
        responseError?.includes("invalid phone number") ||
        responseError?.includes("Failed to send OTP")
      ) {
        setError("errorPhone");
      } else if (responseError?.includes("Please try again in")) {
        setError("errorTooFast");
      }
    } finally {
      setIsLoadingOtp(false);
    }
  };

  const handleCode = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoadingVerify(true);
    try {
      const { data } = await validateOTP(phone, code);
      const token = data.token;
      handleAuth(token);
    } catch (e) {
      setError(
        e.response.data.error === "Invalid OTP" ? "errorOtp" : "errorOther",
      );
    } finally {
      setIsLoadingVerify(false);
    }
  };

  return (
    <div className="Login">
      {!sentPhone ? (
        <form>
          <h2>{t("enterPhone")}</h2>
          <div className="input-group">
            <DigitInputElement autoFocus {...digits[0]} />
            <DigitInputElement {...digits[1]} />
            <DigitInputElement {...digits[2]} />
            <span className="hyphen" />
            <DigitInputElement {...digits[3]} />
            <DigitInputElement {...digits[4]} />
            <DigitInputElement {...digits[5]} />
            <DigitInputElement {...digits[6]} />
            <DigitInputElement {...digits[7]} />
            <DigitInputElement {...digits[8]} />
            <DigitInputElement {...digits[9]} />
          </div>
          <LoadingButton
            className="send-btn"
            id="send-phone-btn"
            variant="contained"
            size="large"
            disabled={phone.trim().length < 10}
            onClick={handlePhone}
            loading={isLoadingOtp}
            type="submit"
          >
            {t("sendCode")}
          </LoadingButton>
          {error && <div className="error">{t(error)}</div>}
        </form>
      ) : (
        <form>
          <h2>{t("enterCode")}</h2>
          <div className="code">
            <OtpInput
              value={code}
              onChange={onCodeChange}
              numInputs={6}
              separator={<span></span>}
              shouldAutoFocus
              inputProps={{
                inputMode: "numeric",
              }}
            />
          </div>
          <LoadingButton
            className="send-btn"
            id="send-code-btn"
            variant="contained"
            size="large"
            onClick={handleCode}
            loading={isLoadingVerify}
            disabled={isLoadingOtp || code.length < 6}
            type="submit"
          >
            {t("continue")}
          </LoadingButton>
          <LoadingButton
            disabled={isLoadingVerify}
            loading={isLoadingOtp}
            className="send-again-btn"
            id="send-code-again-btn"
            variant="text"
            size="large"
            onClick={handlePhone}
          >
            {t("sendAgain")}
          </LoadingButton>
          {error && <div className="error">{t(error)}</div>}
        </form>
      )}
    </div>
  );
};

export default Login;
