import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { useMessage } from "../../i18n";

import "./MoreInfo.scss";

const MoreInfo = ({ state, dispatch }) => {
  const { moreInfoPhotographerName, moreInfoWho, moreInfoWhen, moreInfoMore } =
    state;

  const t = useMessage();

  return (
    <div className="MoreInfo">
      <h1>{t("moreInfoTitle")}</h1>

      <label>{t("moreInfoPhotographerName")}</label>
      <Box noValidate autoComplete="off">
        <TextField
          variant="outlined"
          size="small"
          id="photographer-name-input"
          inputProps={{ maxLength: 165 }}
          value={moreInfoPhotographerName ?? ""}
          onChange={(e) => {
            dispatch({
              type: "moreInfoPhotographerName",
              value: e.target.value,
            });
          }}
        />
      </Box>

      <label>{t("moreInfoWho")}</label>
      <Box noValidate autoComplete="off">
        <TextField
          variant="outlined"
          size="small"
          id="who-is-in-photo-input"
          inputProps={{ maxLength: 165 }}
          value={moreInfoWho ?? ""}
          onChange={(e) => {
            dispatch({
              type: "moreInfoWho",
              value: e.target.value,
            });
          }}
        />
      </Box>
      <label>{t("moreInfoWhen")}</label>
      <Box noValidate autoComplete="off" className="datetime">
        <TextField
          variant="outlined"
          size="small"
          id="when-photo-input"
          inputProps={{ maxLength: 100 }}
          type="datetime-local"
          value={moreInfoWhen}
          onChange={(e) => {
            dispatch({
              type: "moreInfoWhen",
              value: e.target.value,
            });
          }}
        />
      </Box>

      <label>{t("moreInfoMore")}</label>
      <Box
        noValidate
        autoComplete="off"
        sx={{
          "& .MuiFormHelperText-root": {
            color: "#ccc",
          },
        }}
      >
        <TextField
          variant="outlined"
          size="small"
          id="more-info-input"
          multiline
          rows={2}
          inputProps={{ maxLength: 165 }}
          value={moreInfoMore ?? ""}
          helperText={`${moreInfoMore.length}/165`}
          onChange={(e) => {
            dispatch({
              type: "moreInfoMore",
              value: e.target.value,
            });
          }}
        />
      </Box>
    </div>
  );
};

export default MoreInfo;
