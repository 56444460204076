import React, { useEffect, useState } from "react";
import { getIcon, imageToBase64 } from "./helpers";

export const Image = ({ file }) => {
  const [base64, setBase64] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    imageToBase64(file)
      .then((img) => {
        setBase64(img);
      })
      .catch((error) => {
        setError(error);
      });
  }, [file]);

  if (base64) {
    return (
      <div className="image">
        <img src={base64} alt={file.name} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="others">
        {getIcon(file.type)}
        <p className="text">{file.name}</p>
      </div>
    );
  }
};
