import React from "react";
import { useMessage } from "../../i18n";
import Box from "@mui/material/Box";
import "./Loading.scss";
import CircularWithValueLabel from "./CircularProgressWithLabel";

const Loading = ({ uploaded, total }) => {
  const t = useMessage();

  return (
    <Box className="Loading">
      <h1>
        {t("waitWhileUploading")}
        <br />
        {t("waitWhileUploadingLine2")}
      </h1>
      <CircularWithValueLabel
        size="10rem"
        value={t("uploadStatus", { x: uploaded + 1, y: total })}
      />
      <p>{t("uploadPleaseWait")}</p>
    </Box>
  );
};

export default Loading;
