import { useIntl } from "react-intl";
import { messages } from "./messages";

// i18n hook for react component.
// Example:
//    import { useMessage } from '../../i18n';
// Then use it in your component as:
//    const t = useMessage();
//    <h1>{t('appName')}</h1>
export const useMessage = () => {
  const { formatMessage } = useIntl();

  return (msg, params) => {
    if (!messages[msg]) {
      console.error(`Message ID ${msg} not found`);

      return missingTranslation(msg);
    }

    return formatMessage(messages[msg], params);
  };
};

const missingTranslation = (msg) => {
  if (process.env.NODE_ENV === "production") {
    return msg;
  }
  throw new Error(`Missing translation for ${msg}`);
};
