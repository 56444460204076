import React from "react";
import Movie from "@mui/icons-material/Movie";
import PhotoSizeSelectActual from "@mui/icons-material/PhotoSizeSelectActual";
import Audiotrack from "@mui/icons-material/Audiotrack";

export const imageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = () => {
      reject("error");
    };
    reader.readAsDataURL(file);
  });
};

export const getIcon = (type) => {
  if (type.includes("video")) {
    return <Movie />;
  } else if (type.includes("image")) {
    return <PhotoSizeSelectActual />;
  }
  return <Audiotrack />;
};
