import React from "react";
import { useMessage } from "../../i18n";
import "./Share.scss";
import { Share as ShareIcon } from "@mui/icons-material";

const Share = () => {
  const t = useMessage();

  const shareThis = () => {
    navigator.share({
      url: "https://upload.mokedarava.org/",
      title: t("title"),
      text: t("description"),
    });
  };

  if (!navigator.canShare) {
    return null;
  }

  return (
    <div className="Share">
      <ShareIcon />
      <div>
        <p className="contact-label">{t("share")}</p>
        <button className="link" id="share-btn" onClick={shareThis}>
          {t("shareClickHere")}
        </button>
      </div>
    </div>
  );
};

export default Share;
