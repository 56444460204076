import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.MuiTextField-root": {
            margin: "0",
            width: "370px",
            maxWidth: "90vw",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          "&:hover": {
            backgroundColor: "#eee",
          },
          "&.Mui-disabled[disabled]": {
            backgroundColor: "#eee",
            opacity: "0.8"
          },
          "&.MuiButton-text": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          maxWidth: "90vw",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#eee",
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "#eee",
            fontWeight: "bold",
          },
        },
      },
    },
  },
});
