export const initialState = {
  whoTookIt: "",
  eventLabel: "",
  email: "",
  moreInfoPhotographerName: "",
  moreInfoWho: "",
  moreInfoWhen: "",
  moreInfoMore: "",
};

export const PHASES = {
  INITIAL: "initial",
  PREVIEW: "preview",
  UPLOADING: "uploading",
  UPLOAD_COMPLETE: "upload_complete",
};

export const KNOWN_EVENT_NAMES = ["eventDontKnow", "eventNova", "eventPsyduck"];
