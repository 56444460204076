import React from "react";
import ReactFileReader from "react-file-reader";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Clear from "@mui/icons-material/Clear";
import UploadFile from "@mui/icons-material/UploadFile";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useMessage } from "../../i18n";
import MoreInfo from "../MoreInfo/MoreInfo";
import { Image } from "./Image";
import { getIcon } from "./helpers";
import "./Preview.scss";

const Preview = ({ files, onSubmit, onDelete, onAdd, state, dispatch }) => {
  const t = useMessage();

  const filesToPreview = () => {
    const comps = [];
    for (let index = 0; index < files.length; index++) {
      const { type, name } = files[index];
      if (type.includes("image")) {
        comps.push(<Image file={files[index]} key={name} />);
      } else {
        comps.push(
          <div className="others" key={name}>
            {getIcon(type)}
            <p className="text">{name}</p>
          </div>,
        );
      }
    }
    return comps;
  };

  const numberOfAssets = filesToPreview().length;

  return (
    <Box className="Preview">
      <h2>{t("numberOfUploads", { x: numberOfAssets })}</h2>
      <Box
        className="slider"
        sx={{
          "& .carousel__slider--horizontal": {
            transform: "scaleX(1)",
          },
        }}
      >
        <CarouselProvider
          naturalSlideHeight={150}
          naturalSlideWidth={500}
          visibleSlides={numberOfAssets === 0 ? 1 : 2}
          totalSlides={numberOfAssets + 1}
        >
          <Slider>
            <Slide index={0}>
              <div className="uploadMore">
                <ReactFileReader
                  base64={true}
                  multipleFiles
                  handleFiles={(files) => {
                    onAdd([...files.fileList], files.base64);
                  }}
                  fileTypes={["audio/*", "video/*", "image/*"]}
                >
                  <Button className="upload-btn" variant="text" size="large">
                    <UploadFile fontSize="large" />
                    {t("uploadAssets")}
                  </Button>
                </ReactFileReader>
              </div>
            </Slide>
            {filesToPreview().map((sliderContent, index) => {
              return (
                <Slide index={index + 1} key={index} className="slide">
                  {sliderContent}
                  <Clear
                    role="button"
                    onClick={() => {
                      onDelete(index);
                    }}
                    fontSize="large"
                  />
                </Slide>
              );
            })}
          </Slider>
          <ButtonBack className="prev" id="preview-carousel-prev-btn">
            <ArrowBackIosIcon />
          </ButtonBack>
          <ButtonNext className="next" id="preview-carousel-next-btn">
            <ArrowForwardIosIcon />
          </ButtonNext>
        </CarouselProvider>
      </Box>
      <MoreInfo state={state} dispatch={dispatch} />
      <Box sx={{ py: 5 }} className="Buttons">
        <Button
          className="upload-btn"
          id="upload-start-btn"
          variant="contained"
          size="large"
          onClick={onSubmit}
          disabled={files.length === 0}
        >
          {t("moreInfoSend")}
        </Button>
      </Box>
    </Box>
  );
};

export default Preview;
