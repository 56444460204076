export const getContextFromState = (state) => {
  const {
    email,
    eventLabel,
    whoTookIt,
    moreInfoPhotographerName,
    moreInfoWho,
    moreInfoWhen,
    moreInfoMore,
  } = state;

  return {
    email,
    "city-or-event": eventLabel,
    origin: whoTookIt,
    "who-appeared-in": moreInfoWho,
    "photographer-name": moreInfoPhotographerName,
    "date-taken": moreInfoWhen,
    "more-details": moreInfoMore?.replaceAll(/[\r\n]+/g, "; "),
  };
};
