import React from "react";
import { isMobileOnly } from "react-device-detect";
import { ReactComponent as Logo } from "./logo.svg";
import LanguageChanger from "./LanguageChanger";
import "./Header.scss";

const Header = ({ setLocale }) => {
  return (
    <div className="Header">
      <div className={`HeaderContent ${isMobileOnly ? "AlignRight" : ""}`}>
        <LanguageChanger setLocale={setLocale} />
        <Logo className="Logo" />
      </div>
    </div>
  );
};

export default Header;
