import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useMessage } from "../../i18n";
import Logo from "../Logo/Logo";

import Contact from "../Contact";
import "./Intro.scss";

const Intro = ({ handleContinue }) => {
  const t = useMessage();
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="Intro">
      <Logo />
      <h1>{t("introTitle")}</h1>
      <p>{t("introContent")}</p>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Button
          className="btn-more"
          id="intro-show-more-btn"
          variant="text"
          size="large"
          startIcon={
            showMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
          }
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? t("introReadLess") : t("introReadMore")}
        </Button>
      </Box>
      {showMore && <p>{t("introMoreContent")}</p>}
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Button
          className="btn-continue"
          id="intro-continue-btn"
          variant="contained"
          size="large"
          onClick={() => handleContinue(true)}
        >
          {t("continue")}
        </Button>
      </Box>
      <Contact />
    </div>
  );
};

export default Intro;
