import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "title",
    defaultMessage:
      "פנייה לקבלת מידע מהציבור אודות מתקפת הפתע על ישראל, אוקטובר 2023",
  },
  description: {
    id: "description",
    defaultMessage:
      "מאגר זה הינו חלק מיוזמה אזרחית לאיתור וחילוץ החטופים והנעדרים - בשיתוף מוקד ערב“ה ומערך הדיגיטל הלאומי, ליצירת מערכת ייעודית להעלאת סרטונים, תמונות ומידע בקשר עם אירועי ה- 07.10.2023. זאת, על מנת להשלים את תמונת הידע של מדינת ישראל ולאפשר לרשויות החוק ובשיתוף עם ארגונים אזרחיים, איסוף, שימור וניתוח של המידע.",
  },
  enterPhone: {
    id: "enterPhone",
    defaultMessage:
      "לצורך אימות, אנא הזן את מספר הטלפון שלך ונשלח לך קוד להמשך",
  },
  sendCode: {
    id: "sendCode",
    defaultMessage: "שלחו לי קוד",
  },
  sendAgain: {
    id: "sendAgain",
    defaultMessage: "לא קיבלתי קוד, שילחו לי שוב",
  },
  enterCode: {
    id: "enterCode",
    defaultMessage: "הזינו את הקוד שקיבלתם במסרון ולחצו להמשך",
  },
  uploadAssets: {
    id: "uploadAssets",
    defaultMessage: "צרף קבצים",
  },
  whatEvent: {
    id: "whatEvent",
    defaultMessage: "איזה ישוב או ארוע מתועד בצילומים?",
  },
  whoTookIt: {
    id: "whoTookIt",
    defaultMessage: "האם צילמת בעצמך? או קיבלת ממקור אחר?",
  },
  email: {
    id: "email",
    defaultMessage: "אי-מייל (לא חובה)",
  },
  invalidEmail: {
    id: "invalidEmail",
    defaultMessage: "כתובת האי-מייל אינה תקינה",
  },
  tookItMyself: {
    id: "tookItMyself",
    defaultMessage: "צילמתי בעצמי",
  },
  someoneElse: {
    id: "someoneElse",
    defaultMessage: "קיבלתי ממישהו",
  },
  downloaded: {
    id: "downloaded",
    defaultMessage: "הורדתי מהרשת",
  },
  continue: {
    id: "continue",
    defaultMessage: "המשך",
  },
  moreInfoTitle: {
    id: "moreInfoTitle",
    defaultMessage: "איזה עוד מידע תוכל להוסיף?",
  },
  moreInfoPhotographerName: {
    id: "moreInfoPhotographerName",
    defaultMessage: "שם הצלם",
  },
  moreInfoWho: {
    id: "moreInfoWho",
    defaultMessage: "מי מופיע בצילום?",
  },
  moreInfoWhen: {
    id: "moreInfoWhen",
    defaultMessage: "מתי צולם?",
  },
  moreInfoMore: {
    id: "moreInfoMore",
    defaultMessage: "הערות נוספות?",
  },
  moreInfoSend: {
    id: "moreInfoSend",
    defaultMessage: "שלחו צילומים",
  },
  numberOfUploads: {
    id: "numberOfUploads",
    defaultMessage: "בחרת {x} צילומים",
  },
  waitWhileUploading: {
    id: "waitWhileUploading",
    defaultMessage: "רק רגע,",
  },
  waitWhileUploadingLine2: {
    id: "waitWhileUploadingLine2",
    defaultMessage: "מעלה את הצילומים",
  },
  uploadFinish: {
    id: "uploadFinish",
    defaultMessage: "המידע נשמר בהצלחה ויסייע לנו רבות.",
  },
  uploadFinishLine2: {
    id: "uploadFinishLine2",
    defaultMessage: "תודה רבה על העזרה!",
  },
  uploadFinishError: {
    id: "uploadFinishError",
    defaultMessage: "ההעלאה הסתיימה אך חלק מהקבצים לא עלו בהצלחה.",
  },
  uploadFinishShareMore: {
    id: "uploadFinishShareMore",
    defaultMessage: "שתפו עוד צילומים",
  },
  previewTitle: {
    id: "previewTitle",
    defaultMessage: "הבחירה שלך",
  },
  previewMoreInfo: {
    id: "previewMoreInfo",
    defaultMessage: "יש לי עוד מידע לשתף",
  },
  errorOtp: {
    id: "errorOtp",
    defaultMessage: "הקוד שהקשת שגוי",
  },
  errorOther: {
    id: "errorOther",
    defaultMessage: "התרחשה שגיאה בלתי צפויה",
  },
  errorPhone: {
    id: "errorPhone",
    defaultMessage: "מספר הטלפון לא תקין",
  },
  errorTooFast: {
    id: "errorTooFast",
    defaultMessage: "המתינו 30 שניות לפני בקשת קוד נוסף",
  },
  uploadStatus: {
    id: "uploadStatus",
    defaultMessage: "{x} מתוך {y}",
  },
  uploadPleaseWait: {
    id: "uploadPleaseWait",
    defaultMessage: "הישאר במסך זה עד לסיום העלאה",
  },
  introTitle: {
    id: "introTitle",
    defaultMessage:
      "פנייה לקבלת מידע מהציבור אודות מתקפת הפתע על ישראל, אוקטובר 2023",
  },
  introContent: {
    id: "introContent",
    defaultMessage: `איסוף המידע נועד על מנת להשלים את תמונת הידע של מדינת ישראל על אירועי ה 07.10.2023, באופן שיאפשר טיפול מיטבי בנפגעי מתקפת הפתע, יסייע באיתור נעדרים ובזיהוי החללים, ויתרום לביטחון המדינה. המידע שנאסף יועבר למוקד משפחות לנעדרים נפגעי פעולות איבה ("מוקד 105") של משטרת ישראל, וכן לגופי הביטחון והמודיעין של מדינת ישראל.
    ייעשה שימוש במידע רק למטרות שלשמן התקבל. מוסר המידע עושה זאת בהתנדבות, ולא יישא באחריות אישית פלילית כלשהי בעקבות מסירת מידע שנעשתה בתום לב.`,
  },
  introReadMore: {
    id: "introReadMore",
    defaultMessage: "למידע נוסף על היוזמה",
  },
  introReadLess: {
    id: "introReadLess",
    defaultMessage: "סגור מידע נוסף על היוזמה",
  },
  introMoreContent: {
    id: "introMoreContent",
    defaultMessage: `מוקד ערבה: עזרה ראשונה בטכנולוגיה והטמעה, הוא מוקד טכנולוגי שהוקם בחודש אוקטובר 2023, ומופעל ע"י חברות הייטק מובילות במשק הישראלי בשיתוף עם מערך הדיגיטל הלאומי שבמשרד הכלכלה, כגורם ממשלתי מתכלל. המוקד קולט צרכים של גופים ציבוריים, רשויות מקומיות, משרדי ממשלה, עמותות ועוד, ומסייע ביצירת חיבורים לחברות טכנולוגיה רלוונטיות ובפיתוח פתרונות לצרכים אלה.

    במסגרת הסיוע הטכנולוגי של מוקד ערבה למוקד משפחות לנעדרים נפגעי פעולות איבה ("מוקד 105") של משטרת ישראל, המוקד מעוניין לקבל מהציבור מידע שיש בו כדי לשפוך אור על מתקפת הפתע על ישראל שהחלה ב 07.10.2023. זאת, במטרה להשלים את תמונת הידע של מדינת ישראל על אירועי ה 07.10.2023, באופן שיאפשר טיפול מיטבי בנפגעי מתקפת הפתע, יסייע באיתור נעדרים ובזיהוי החללים, ויתרום לביטחון המדינה ולשיקום החברה הישראלית לאחר המתקפה. המידע הנדרש עשוי לכלול גם (אך לא רק) תמונות, סרטונים וקבצי אודיו שצולמו או הוקלטו במהלך האירועים, ועשויים לכלול גם (אך לא רק) צילומים של התוקפים ושותפיהם, החללים, הנעדרים והעדים לאירועי מתקפת הפתע, וכן של כלי רכב וכלים שונים ששימשו את התוקפים, וכן תמונות נוף ותמונות חפצים דוממים שיש בהן כדי להקל על קביעת המיקום שבו צולמו התמונות והסרטונים.`,
  },
  contactText: {
    id: "contactText",
    defaultMessage: "במקרה של תקלה טכנית בלבד, פנו ל:",
  },
  contactAddress: {
    id: "contactAddress",
    defaultMessage: "tech.support@mokedarava.org",
  },
  share: {
    id: "share",
    defaultMessage: "מכירים מישהו שיש לו צילומים?",
  },
  shareClickHere: {
    id: "shareClickHere",
    defaultMessage: "לחצו פה לשליחת קישור",
  },
  eventDontKnow: {
    id: "eventDontKnow",
    defaultMessage: "אני לא יודע",
  },
  eventNova: {
    id: "eventNova",
    defaultMessage: "מסיבת נובה",
  },
  eventPsyduck: {
    id: "eventPsyduck",
    defaultMessage: "מסיבת פסיידק",
  },
});
