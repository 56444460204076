import React from "react";
import { useMessage } from "../../i18n";

import "./Contact.scss";

const Contact = () => {
  const t = useMessage();

  return (
    <div className="Contact">
      <p className="contact-label">{t("contactText")}</p>
      <a
        className="mailto-link"
        id="contact-link"
        href={`mailto:${t("contactAddress")}`}
      >
        {t("contactAddress")}
      </a>
    </div>
  );
};

export default Contact;
