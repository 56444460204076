import React, { useEffect, useReducer, useState, useRef, useMemo } from "react";
import ReactFileReader from "react-file-reader";
import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Autocomplete } from "@mui/material";
import { useMessage } from "../../i18n";
import { openUploadWidget } from "../../utils/uploadWidget";
import { CLOUDNAME } from "../../data/consts";
import Loading from "../Loading/Loading";
import Preview from "../Preview/Preview";
import UploadComplete from "../UploadComplete/UploadComplete";
import { PHASES, KNOWN_EVENT_NAMES, initialState } from "./consts";
import { getContextFromState } from "./helpers";
import { signParams, getCitiesGov } from "../../data/apis";
import "./Upload.scss";

const isNilOrWhitespace = (input) => (input?.trim()?.length || 0) === 0;

const reducer = (state, action) => {
  return {
    ...state,
    [action.type]: action.value,
  };
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Upload = ({ token, logout }) => {
  const t = useMessage();
  const { locale } = useIntl();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { whoTookIt, eventLabel, email } = state;

  const uploadWidget = useRef();

  const [phase, setPhase] = useState(PHASES.INITIAL);

  const [events, setEvents] = useState([]);

  const [error, setError] = useState(false);

  const [emailError, setEmailError] = useState(false);

  const [files, setFiles] = useState([]);

  const [uploaded, setUploaded] = useState(0);

  const isDisabled =
    isNilOrWhitespace(whoTookIt) ||
    isNilOrWhitespace(eventLabel) ||
    (!isNilOrWhitespace(email) && emailError);

  const allEvents = useMemo(() => {
    const defaultEvents = KNOWN_EVENT_NAMES.map((eventName) => t(eventName));

    const cities = events.map((city) => {
      return locale === "he-HE" ? city["cityHe"] : city["cityEn"];
    });

    return [...defaultEvents, ...new Set(cities)];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, events]);

  const handleFiles = (selectedFiles) => {
    setFiles([...selectedFiles.fileList]);
    setPhase(PHASES.PREVIEW);
  };

  const handleUpload = async () => {
    try {
      const data = await signParams(
        {
          context: getContextFromState(state),
        },
        token,
      );
      const params = data.data.params;
      uploadWidget.current = openUploadWidget(
        {
          cloudName: CLOUDNAME,
          ...params,
          uploadSignature: params.signature,
          uploadSignatureTimestamp: params.timestamp,
          sources: ["local"],
          autoMinimize: true,
          concurrentUploads: 2,
          upload_prefix: `https://${CLOUDNAME}.api-fast.cloudinary.com/`,
        },
        function (error, result) {
          if (result.event === "source-changed") {
            uploadWidget.current.open(null, { files });
          } else if (!error && result.event === "success") {
            setUploaded((prev) => prev + 1);
          } else if (!error && result.event === "queues-end") {
            setPhase(PHASES.UPLOAD_COMPLETE);
          } else if (error) {
            setError(true);
          }
        },
      );
    } catch (e) {
      logout();
    }
  };

  useEffect(() => {
    uploadWidget.current?.destroy();
    const getCities = async () => {
      const cities = await getCitiesGov();
      setEvents([...cities]);
    };

    getCities();
  }, []);

  if (phase === PHASES.UPLOAD_COMPLETE) {
    return (
      <UploadComplete
        error={error}
        onSubmit={() => {
          setUploaded(0);
          setPhase(PHASES.INITIAL);
          setError(false);
        }}
      />
    );
  }

  if (phase === PHASES.UPLOADING) {
    return <Loading uploaded={uploaded} total={files.length} />;
  }

  if (phase === PHASES.PREVIEW) {
    return (
      <Preview
        state={state}
        dispatch={dispatch}
        files={files}
        onDelete={(indexToRemove) => {
          setFiles((prev) =>
            prev.filter((item, index) => index !== indexToRemove),
          );
        }}
        onAdd={(newFiles) => {
          setFiles((prev) => [...newFiles, ...prev]);
        }}
        onSubmit={async () => {
          setPhase(PHASES.UPLOADING);
          await handleUpload();
        }}
      />
    );
  }

  return (
    <div className="Upload">
      <Box
        sx={{
          "& .MuiFormHelperText-root.Mui-error": {
            textAlign: "center",
            position: "absolute",
            inset: 0,
            top: "100%",
          },
        }}
      >
        <label>{t("whatEvent")}</label>
        <Autocomplete
          disableClearable
          freeSolo
          options={allEvents}
          value={eventLabel ?? ""}
          onBlur={(e) => {
            dispatch({
              type: "eventLabel",
              value: e.target.value,
            });
          }}
          onChange={(e, value) => {
            dispatch({
              type: "eventLabel",
              value,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id="event-label-input"
              variant="outlined"
              size="small"
              inputProps={{ ...params.inputProps, maxLength: 165 }}
            />
          )}
        />

        <label>{t("whoTookIt")}</label>

        <ToggleButtonGroup
          className="buttonToggle"
          orientation="vertical"
          dir="ltr"
          color="primary"
          value={whoTookIt}
          id="source-select-btn"
          exclusive
          onChange={(e) => {
            dispatch({
              type: "whoTookIt",
              value: e.target.value,
            });
          }}
          size="large"
        >
          <ToggleButton value="הורדתי מהרשת">{t("downloaded")}</ToggleButton>
          <ToggleButton value="קיבלתי ממישהו">{t("someoneElse")}</ToggleButton>
          <ToggleButton value="צלמתי בעצמי">{t("tookItMyself")}</ToggleButton>
        </ToggleButtonGroup>

        <label>{t("email")}</label>
        <TextField
          type="email"
          id="email-input"
          dir="ltr"
          value={email ?? ""}
          onChange={(e) => {
            setEmailError(!emailRegex.test(e.target.value));
            dispatch({
              type: "email",
              value: e.target.value,
            });
          }}
          variant="outlined"
          size="small"
          error={emailError}
          helperText={emailError ? t("invalidEmail") : ""}
        />
      </Box>

      <Box
        sx={{
          py: 5,
          "& .MuiButton-root": {
            m: 1,
            width: "370px",
            maxWidth: "90vw",
            height: "60px",
          },
        }}
        className={isDisabled ? "disabled Buttons" : "Buttons"}
      >
        <ReactFileReader
          base64={true}
          multipleFiles
          handleFiles={handleFiles}
          fileTypes={["audio/*", "video/*", "image/*"]}
        >
          <Button
            className="upload-btn"
            id="select-files-btn"
            variant="contained"
            size="large"
            disabled={isDisabled}
          >
            {t("uploadAssets")}
          </Button>
        </ReactFileReader>
      </Box>
    </div>
  );
};

export default Upload;
