import React, { useState } from "react";
import { Language } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import "./LanguageChanger.scss";
import { useIntl } from "react-intl";

const LanguageChanger = ({ setLocale }) => {
  const { locale } = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (locale) => {
    setLocale(locale);
    handleClose();
  };

  return (
    <div className="language">
      <Button
        variant="text"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        id="localization"
        className="ChangeLang"
      >
        <Language fontSize="medium" color="action" />
        <div className="LangName">{locale.replace(/[a-z]{2}-/, "")}</div>
      </Button>
      <Menu
        id="localization-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          id="locale-hebrew"
          onClick={() => changeLanguage("he-HE")}
          selected={locale === "he-HE"}
        >
          עברית
        </MenuItem>
        <MenuItem
          id="locale-english"
          onClick={() => changeLanguage("en-EN")}
          selected={locale === "en-EN"}
        >
          English
        </MenuItem>
        <MenuItem
          id="locale-russian"
          onClick={() => changeLanguage("ru-RU")}
          selected={locale === "ru-RU"}
        >
          Русский
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageChanger;
