import React from "react";
import inda from "../Contact/inda-trans.png";
import "./Logo.scss";

const Logo = () => {
  return (
    <div className="IndaLogo">
      <img src={inda} alt="מערך הדיגיטל הלאומי" className="logo" />
    </div>
  );
};

export default Logo;
