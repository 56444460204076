import React from "react";
import "./UploadComplete.scss";
import Box from "@mui/material/Box";
import { useMessage } from "../../i18n";
import Button from "@mui/material/Button";
import Logo from "../Logo/Logo";
import Contact from "../Contact";
import Share from "./Share";

const UploadComplete = ({ onSubmit, error }) => {
  const t = useMessage();
  return (
    <Box className="UploadComplete">
      <Logo />
      {!error && (
        <h1>
          {t("uploadFinish")} <br /> {t("uploadFinishLine2")}
        </h1>
      )}
      {error && <h1>{t("uploadFinishError")}</h1>}
      {error && <p>{error}</p>}
      <Box sx={{ py: 5 }}>
        <Button
          className="upload-btn"
          id="upload-complete-btn"
          variant="contained"
          size="large"
          onClick={onSubmit}
        >
          {t("uploadFinishShareMore")}
        </Button>
      </Box>
      <Share />
      <Contact />
    </Box>
  );
};

export default UploadComplete;
