import React from "react";

const DigitInputElement = React.forwardRef(({...props}, ref) => {
  return (
    <label>
      <div className="digit">{props.value}</div>
      <hr />
      <input {...props} ref={ref} inputMode="decimal" />
    </label>
  );
});

export default DigitInputElement;
