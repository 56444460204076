import en from './en-EN.json';
import ru from './ru-RU.json';

const translations = {
  "en-EN": en,
  "ru-RU": ru,
  "he-He": null
};

export default translations;
